<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                {{ program.title }}
              </h3>
              <!--              <b-button-->
              <!--                v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
              <!--                variant="outline-secondary"-->
              <!--                class="ml-auto"-->
              <!--                @click="$router.push(`/programs/${program.id}/manage`)"-->
              <!--              >Go to Program Manager</b-button>-->
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              class="h-scroll-tabs"
              nav-class="mb-2"
              pills
              @input="updateHash"
            >
              <b-tab
                active
                class="text-left"
                href="Rounds"
                lazy
                title="Rounds"
              >
                <rounds-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Calls"
                lazy
                title="Call for Application"
              >
                <calls-manager />
              </b-tab>
              <b-tab
                href="Jury"
                lazy
                title="Jury"
              >
                <jury-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Applicants"
                lazy
                title="Applicants"
              >
                <applicants-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Participants"
                lazy
                title="Participants"
              >
                <participants-manager />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import RoundsManager from '@/views/incubator-portal/programs/pages/startups/application-manager/RoundsManager.vue'
import CallsManager from '@/views/incubator-portal/programs/pages/startups/application-manager/CallsManager.vue'
import ApplicantsManager from '@/views/incubator-portal/programs/pages/startups/application-manager/ApplicantsManager.vue'
import JuryManager from '@/views/incubator-portal/programs/pages/startups/application-manager/JuryManager.vue'
import ParticipantsManager from '@/views/incubator-portal/programs/pages/startups/application-manager/ParticipantsManager.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    RoundsManager,
    CallsManager,
    ApplicantsManager,
    JuryManager,
    ParticipantsManager,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Rounds',
        '#Calls',
        '#Jury',
        '#Applicants',
        '#Participants'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          id
          title
          begin_date
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
