<template>
  <b-modal
    id="addParticipantModal"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title="Add Participant"
  >
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :rows="flattenedData"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'selected-info'
      }"
      @on-selected-rows-change="updateSelectedStartups"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'startup'">
          {{ props.row.startup }}
        </span>
        <span v-else-if="props.column.field === 'roundTitle'">
          <b-badge
            v-b-tooltip="'Round Number'"
            class="mr-50 mb-25"
            variant="primary"
          >{{ props.row.roundNumber }}</b-badge>
          {{ props.row.roundTitle }}
        </span>
        <span v-else-if="props.column.field === 'recommendation'">
          {{ props.row.recommendation }}
        </span>
      </template>

      <template #pagination-bottom="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">Showing 1 to</span>
            <b-form-select
              v-model="pageLength"
              :options="[3, 5, 10]"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">of {{ props.total }} entries</span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-button
      class="mt-2"
      variant="outline-primary"
      @click="handleAddParticipants"
    >
      <feather-icon
        class="mr-25"
        icon="PlusIcon"
      />
      Add
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormSelect, BPagination, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BBadge,
    BModal,
    BButton,
    VueGoodTable,
    BFormSelect,
    BPagination,
  },
  props: {
    rounds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pageLength: 3,
      columns: [
        { label: 'Startup', field: 'startup' },
        { label: 'Round', field: 'roundTitle' },
        { label: 'Recommendation', field: 'recommendation' },
      ],
      selectedStartups: [],
    }
  },
  computed: {
    flattenedData() {
      return this.rounds.flatMap(round => (round.programs_applicantstables || []).map(startup => ({
        id: startup.id,
        startup: startup.users_organizationtable?.title || 'N/A',
        roundTitle: round.title,
        roundNumber: round.round_number,
        recommendation:
            (startup.programs_applicantprogresstables || [])
              .slice(-1)[0]?.recommendation || 'N/A',
      })))
    },
  },
  methods: {
    promoteParticipants() {
      this.$emit('promote-participants', this.selectedStartups)
    },
    handleAddParticipants() {
      this.$bvModal.hide('addParticipantModal')
      this.promoteParticipants()
    },
    updateSelectedStartups(data) {
      this.selectedStartups = data.selectedRows.map(row => row.id)
    },
  },
}
</script>
